@font-face {
  font-family: "GT Pressura Mono Regular";
  src: url("./assets/fonts/GTPressuraMonoRegular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "GT Pressura Mono Regular", "Helvetica Neue", sans-serif;
  font-size: 1.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #201f1d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
