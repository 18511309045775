.app {
  width: 100%;
  height: 100vh;
  color: #e3d8d5;
}

a {
  color: #fff;
  font-weight: 400;
}

a:hover {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.smallText {
  font-size: 0.75em;
}
